<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-25 16:51:19
 * @LastEditTime: 2019-11-29 18:43:46
 * @LastEditors: Please set LastEditors
 -->
<template>
  <div class="produce-manage">
    <page-top :title="$t('restaurant_submenu.8F6D4D@supplier_products')">
      <span style="font-size:26px;" class="fa fa-cogs"></span>
    </page-top>
    <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane
          :label="$t('supplier-produceManage-publishProduct.B7148B@Basic_Settings')"
          name="1"
      >
        <el-row style="margin-top:50px;">
          <el-col :span="16" :offset="4">
            <el-form ref="form" :rules="rules" :model="basicForm" label-width="200px">
              <el-form-item
                  prop="category_id"
                  :label="$t('supplier-produceManage-publishProduct.2072FB@classify_id')"
              >
                <el-cascader
                    v-model="basicForm.category_id"
                    :options="typeOptions"
                    :props="{children:'sub_category',label:'name',value:'cid',emitPath:false }"
                    @change="getCheckedNodes"
                ></el-cascader>
              </el-form-item>
              <el-form-item prop="goods_no" :label="$t('aboutus.21AFC6@Product_No')">
                <el-input type="text" @input.native="$inputLenght" v-model="basicForm.goods_no"></el-input>
              </el-form-item>
              <el-form-item
                  prop="name_hk"
                  :label="$t('supplier-produceManage-publishProduct.BD6C55@Name_hk')"
              >
                <el-input v-model="basicForm.name_hk"></el-input>
              </el-form-item>
              <el-form-item
                  prop="name_en"
                  :label="$t('supplier-produceManage-publishProduct.36F75F@name_en')"
              >
                <el-input v-model="basicForm.name_en"></el-input>
              </el-form-item>
              <el-form-item
                  prop="price"
                  :label="$t('supplier_supplier_produceManage.753635@price')"
              >
                <el-input
                    type="number"
                    v-model="basicForm.price"
                    :disabled="isdisabled"
                ></el-input>
              </el-form-item>
              <el-form-item
                  prop="keywords"
                  :label="$t('supplier-produceManage-publishProduct.3B83F2@keyword')"
              >
                <el-input
                    v-model="basicForm.keywords"
                    :placeholder="$t('supplier-produceManage-publishProduct.226509@Distinguish_keywords_by_Spaces')"
                ></el-input>
              </el-form-item>
              <el-form-item
                  prop="status"
                  :label="$t('supplier-produceManage-publishProduct.92C10D@Shelf')"
              >
                <el-switch
                    v-model="basicForm.status"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                ></el-switch>
              </el-form-item>
              <el-form-item
                  prop="unit_num"
                  :label="$t('supplier-produceManage-publishProduct.DBCC4F@Number_of_Units')"
              >
                <el-input
                    type="number"
                    v-model="basicForm.unit_num"
                    :disabled="isdisabled"
                ></el-input>
              </el-form-item>
              <el-form-item
                  prop="unit_id"
                  :label="$t('supplier-produceManage-publishProduct.4E6A29@Commodity_unit')"
              >
                <el-select style="width:100%;" v-model="basicForm.unit_id" :disabled="isdisabled">
                  <el-option
                      v-for="option in unitOptions"
                      :key="option.unit_id"
                      :value="option.unit_id"
                      :label="option.unit_name"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                  prop="min_quantity"
                  :label="$t('supplier-produceManage-publishProduct.97DB98@Minimum_purchase_quantity')"
              >
                <el-input
                    type="number"
                    @input.native="$inputLenght"
                    v-model="basicForm.min_quantity"
                ></el-input>
              </el-form-item>
              <el-form-item prop="quantity_status" :label="$t('aboutus.A5B775@Quantity_Status')">
                <el-select v-model="basicForm.quantity_status" @change="quantityStatus" clearable>
                  <el-option :label="$t('aboutus.DF0FCE@In_Stock')" :value="1"></el-option>
                  <el-option :label="$t('aboutus.18EF78@Book')" :value="2"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                  v-if="basicForm.quantity_status==2"
                  prop="delivery_time_start"
                  :label="$t('aboutus.E820CB@Estimated_delivery_time')"
              >
                <el-input
                    type="number"
                    @input.native="$inputLenght"
                    v-model="basicForm.delivery_time_start"
                    size="small"
                    style="width:80px"
                ></el-input>&nbsp;
                <i class="el-icon-minus"></i>&nbsp;
                <el-input
                    type="number"
                    @input.native="$inputLenght"
                    v-model="basicForm.delivery_time_end"
                    size="small"
                    style="width:80px"
                ></el-input>
                {{ $t('aboutus.D4D5D3@day') }}
              </el-form-item>
              <el-form-item
                  prop="sort"
                  :label="$t('supplier-produceManage-publishProduct.EE6D61@Sort')"
              >
                <el-input type="number" @input.native="$inputLenght" v-model="basicForm.sort"></el-input>
              </el-form-item>
              <el-form-item
                  :label="$t('supplier-produceManage-publishProduct.E400F7@Product_Image')"
                  label-width="200px">
                <el-upload
                    :show-file-list="false"
                    action=""
                    :before-upload="beforeAvatarUpload"
                    :http-request="uploadFile"
                    list-type="picture-card"
                    multiple>
                  <i class="el-icon-plus"></i>
                  <!--                  <div slot="tips">-->
                  <!--                    第一張圖片將會成為封面圖片-->
                  <!--                  </div>-->
                </el-upload>
                <draggable
                    v-model="basicForm.picture"
                    class="wrapper"
                    tag="ul"
                    v-bind="dragOptions"
                    @start="basicForm.drag = true"
                    @end="basicForm.drag = false">
                  <div v-for="(item,index) in basicForm.picture" :key="item" v-loading="basicForm.loading"
                       @mouseenter="showDelBtn(index)" @mouseleave="hiddenDelBtn"
                       style="padding-top: 1vh;margin-right:10px;height: 160px;width: 120px;" class="block">
                    <el-col align="center">
                      <el-image v-if="item" :src="item" style="height: 120px;width: 120px;" fit="fill"></el-image>
                      <el-button type="danger" size="small" @click="deleImg(item,index)">刪除</el-button>
                    </el-col>
                  </div>
                </draggable>
              </el-form-item>
              <el-form-item prop="description" :label="$t('shop-goods.299596@Remarks')">
                <el-input type="textarea" :rows="5" v-model="basicForm.description"></el-input>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </el-tab-pane>
      <!-- <el-tab-pane
        :label="$t('supplier-produceManage-publishProduct.98875D@Product_specifications')"
        name="2"
      >
        <section style="overflow:hidden;">
          <el-button
            style="float:right;margin-left:10px;"
            size="small"
            type="danger"
            plain
            @click="tableData=[]"
          >{{$t('supplier-produceManage-publishProduct.799C97@Clear_specification')}}</el-button>
          <el-button
            style="float:right;"
            size="small"
            type="primary"
            plain
            @click="showDialog"
          >{{$t('supplier-produceManage-publishProduct.D4430E@New_specifications')}}</el-button>
        </section>
        <el-table :data="tableData" style="width: 100%;">
          <el-table-column
            prop="sku_name"
            :label="$t('supplier_partner.34C475@name')"
            align="center"
            width="200"
          ></el-table-column>
          <el-table-column
            prop="price"
            :label="$t('supplier_supplier_produceManage.753635@price')"
            align="center"
          >
            <template slot-scope="scope">
              <el-input style="width:80%" v-model="scope.row.price"></el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="unit_num"
            :label="$t('supplier-produceManage-publishProduct.E9BF3B@Number_of_Units_Commodity_unit')"
            align="center"
          >
            <template slot-scope="scope">
              <el-input v-model="scope.row.unit_num" class="input-with-select">
                <el-select
                  style="width:150px;"
                  v-model="scope.row.unit_id"
                  slot="append"
                  :placeholder="$t('placeholder.F0DDC0@select')"
                  @focus="getUnit"
                >
                  <el-option
                    v-for="option in unitOptions"
                    :key="option.unit_id"
                    :value="option.unit_id"
                    :label="option.unit_name"
                  ></el-option>
                </el-select>
              </el-input>
            </template>
          </el-table-column>
        </el-table>
        <el-dialog :title="$t('public.553159@tips')" :visible.sync="specDialog" width="45%">
          <el-row>
            <el-col :span="16" :offset="4">
              <el-select
                v-model="value"
                :placeholder="$t('placeholder.F0DDC0@select')"
                @change="productSpec"
                multiple
              >
                <el-option
                  v-for="item in dialogOptions"
                  :key="item.value"
                  :label="item.spec_name"
                  :value="item.value_id"
                ></el-option>
              </el-select>
              <div style="margin-top:20px;">
                <el-checkbox-group
                  v-model="checkboxGroup1"
                  v-for="(item,index) in cities"
                  :key="index"
                >
                  <el-checkbox-button
                    v-for="city in item"
                    :label="city.value_id"
                    :key="city.value_id"
                  >{{city.value}}</el-checkbox-button>
                </el-checkbox-group>
              </div>
            </el-col>
          </el-row>
          <span slot="footer" class="dialog-footer">
            <el-button size="mini" @click="specDialog = false">{{$t('public.836FD4@cancel')}}</el-button>
            <el-button
              size="mini"
              type="primary"
              @click="confirmDialog"
            >{{$t('public.3B45A2@confirm')}}</el-button>
          </span>
        </el-dialog>
      </el-tab-pane> -->
    </el-tabs>
    <section style="width:100px;margin:30px auto 0;">
      <el-button size="mini" type="primary" @click="saveForm('form')">{{ $t('public.1A3EBC@save') }}</el-button>
    </section>
  </div>
</template>

<script>
import api from "../../http/api";
import pageTop from "../../components/PageTop/PageTop";
import draggable from 'vuedraggable';

export default {
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },
  data() {
    return {
      activeName: "1",
      typeOptions: [],
      unitOptions: [],
      basicForm: {
        relation_id: undefined,
        category_id: undefined,
        goods_no: "",
        name_en: "",
        name_hk: "",
        picture: [],
        album: [],
        price: "",
        quantity_status: "",
        min_quantity: "",
        keywords: "",
        intro_en: "",
        intro_hk: "",
        status: true,
        unit_num: "",
        unit_id: "",
        delivery_time_start: 1,
        delivery_time_end: 2,

        sort: "",
        specs_json: "",
        sku_json: "",
        currentDelBtn: -1,
        drag: false,
        loading: false,
      },
      rules: {
        category_id: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "change"
        },
        name_hk: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "blur"
        },
        name_en: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "blur"
        },
        price: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "blur"
        },
        status: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "blur"
        },
        unit_num: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "blur"
        },
        unit_id: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "change"
        },
        min_quantity: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "blur"
        },
        quantity_status: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "change"
        },
        delivery_time_start: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "blur"
        }
      },

      // 商品规格
      specDialog: false,
      value: "",
      cities: [],
      dialogOptions: [],
      checkboxGroup1: [],
      tableData: [],
      isdisabled: false
    };
  },

  components: {
    pageTop,
    draggable
  },
  watch: {
    tableData(newData, oldData) {
      if (newData.length > 0) {
        this.rules = {
          relation_id: {
            required: true,
            message: this.$t("aboutus.B003A6@This_is_required"),
            trigger: "change"
          },
          category_id: {
            required: true,
            message: this.$t("aboutus.B003A6@This_is_required"),
            trigger: "change"
          },
          name_hk: {
            required: true,
            message: this.$t("aboutus.B003A6@This_is_required"),
            trigger: "blur"
          },
          name_en: {
            required: true,
            message: this.$t("aboutus.B003A6@This_is_required"),
            trigger: "blur"
          },
          quantity_status: {
            required: true,
            message: this.$t("aboutus.B003A6@This_is_required"),
            trigger: "change"
          },
          delivery_time_start: {
            required: true,
            message: this.$t("aboutus.B003A6@This_is_required"),
            trigger: "change"
          }
        };
      } else {
        this.rules = {
          relation_id: {
            required: true,
            message: this.$t("aboutus.B003A6@This_is_required"),
            trigger: "change"
          },
          category_id: {
            required: true,
            message: this.$t("aboutus.B003A6@This_is_required"),
            trigger: "change"
          },
          name_hk: {
            required: true,
            message: this.$t("aboutus.B003A6@This_is_required"),
            trigger: "blur"
          },
          name_en: {
            required: true,
            message: this.$t("aboutus.B003A6@This_is_required"),
            trigger: "blur"
          },
          price: {
            required: true,
            message: this.$t("aboutus.B003A6@This_is_required"),
            trigger: "blur"
          },
          unit_num: {
            required: true,
            message: this.$t("aboutus.B003A6@This_is_required"),
            trigger: "blur"
          },
          unit_id: {
            required: true,
            message: this.$t("aboutus.B003A6@This_is_required"),
            trigger: "change"
          },
          quantity_status: {
            required: true,
            message: this.$t("aboutus.B003A6@This_is_required"),
            trigger: "change"
          },
          delivery_time_start: {
            required: true,
            message: this.$t("aboutus.B003A6@This_is_required"),
            trigger: "change"
          }
        };
      }
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
    }
  },
  created() {
    if (+this.$route.query.id) {
      this.modify(this.$route.query.id);
    }
    this.getType();
    this.getUnit();
  },
  methods: {
    //显示删除图片的图标
    showDelBtn(index) {
      this.basicForm.currentDelBtn = index
    },
    //隐藏删除图片的图标
    hiddenDelBtn() {
      this.basicForm.currentDelBtn = -1
    },
    // 删除图片
    deleImg(data, index) {
      this.basicForm.picture.splice(index, 1)
    },
    // 获取类型供应
    getType(id) {
      this.$http
          .typeSelect({
            cid: 0
          })
          .then(res => {
            dele_sub(res.data);
            this.typeOptions = res.data;

            // 当选择器数组为空时删除(隐藏暂无数据)
            function dele_sub(data) {
              data.forEach((v, i) => {
                if (v.sub_category.length) {
                  dele_sub(v.sub_category);
                } else {
                  delete v.sub_category;
                }
              });
            }
          });
    },
    quantityStatus(e) {
      if (e == 1) {
        this.basicForm.delivery_time_start = "";
        this.basicForm.delivery_time_end = "";
      } else {
        this.basicForm.delivery_time_start = 1;
        this.basicForm.delivery_time_end = 2;
      }
    },
    getUnit() {
      this.$http.unitSelect().then(res => {
        this.unitOptions = res.data;
      });
    },
    // 选择器选中
    getCheckedNodes(leafOnly) {
      this.basicForm.category_id = leafOnly;
    },
    beforeAvatarUpload(file) {
      let fileNameArr = file.name.split(".");
      let type = fileNameArr[fileNameArr.length - 1].toLowerCase();

      const isJPG = Boolean(type == "jpg" || type == "png" || type == "jpeg");
      const isLt2M = Boolean(file.size / 1024 / 1024 < 5);

      if (!isJPG) {
        this.$message.error(this.$t("aboutus.23D2D3@Upload_failed"));
      }
      if (!isLt2M) {
        this.$message.error(this.$t("aboutus.2F8186@Upload_failed"));
      }
      return isJPG && isLt2M;
    },
    showDialog() {
      this.specDialog = true;
      this.checkboxGroup1 = [];
      this.cities = [];
      this.tableData = [];
      this.value = "";
      this.dialogOptions = [];
      api.productProp().then(res => {
        this.dialogOptions = res.data;
      });
    },

    // 自定义上传图片
    uploadFile(param) {
      console.log("12", param)
      let that = this;
      let reader = new FileReader();
      reader.readAsDataURL(param["file"]);
      reader.onload = function (event) {
        that.basicForm.picture.push(this.result)
        // that.basicForm.picture = this.result;
      };
    },

    // 保存表单
    saveForm(formName) {
      // console.log(JSON.stringify(this.basicForm.picture))
      if (
          this.basicForm.quantity_status == 2 &&
          Boolean(
              !this.basicForm.delivery_time_start ||
              !this.basicForm.delivery_time_end ||
              this.basicForm.delivery_time_start >
              this.basicForm.delivery_time_end
          )
      ) {
        this.$message.warning(
            this.$t("aboutus.CB7991@Wrong_estimated_delivery_time")
        );
        return;
      }

      this.$refs[formName].validate(valid => {
        if (valid) {
          if (+this.$route.query.id) {
            api.modifyProduct({
              gid: this.$route.query.id,
              goods_no: this.basicForm.goods_no,
              category_id: this.basicForm.category_id,
              name_en: this.basicForm.name_en,
              name_hk: this.basicForm.name_hk,
              picture: JSON.stringify(this.basicForm.picture),
              min_quantity: this.basicForm.min_quantity,
              price: this.basicForm.price,
              keywords: this.basicForm.keywords,
              intro_en: this.basicForm.intro_en,
              intro_hk: this.basicForm.intro_hk,
              status: this.basicForm.status,
              unit_num: this.basicForm.unit_num,
              unit_id: this.basicForm.unit_id,
              sort: this.basicForm.sort,
              quantity_status: this.basicForm.quantity_status,
              delivery_time_start: this.basicForm.delivery_time_start,
              delivery_time_end: this.basicForm.delivery_time_end,
              specs_json: JSON.stringify(this.checkboxGroup1),
              sku_json: JSON.stringify(this.tableData),
              description: this.basicForm.description,
            }).then(res => {
              if (res.errno == 0) {
                // this.$refs["form"].resetFields();
                this.tableData = [];
                this.checkboxGroup1 = [];
                // window.location.reload()
              }
            });
          } else {
            // 新增
            api.addProduct({
              goods_no: this.basicForm.goods_no,
              category_id: this.basicForm.category_id,
              name_en: this.basicForm.name_en,
              name_hk: this.basicForm.name_hk,
              picture: JSON.stringify(this.basicForm.picture),
              min_quantity: this.basicForm.min_quantity,
              price: this.basicForm.price,
              keywords: this.basicForm.keywords,
              intro_en: this.basicForm.intro_en,
              intro_hk: this.basicForm.intro_hk,
              status: this.basicForm.status,
              unit_num: this.basicForm.unit_num,
              unit_id: this.basicForm.unit_id,
              sort: this.basicForm.sort,
              quantity_status: this.basicForm.quantity_status,
              delivery_time_start: this.basicForm.delivery_time_start,
              delivery_time_end: this.basicForm.delivery_time_end,
              specs_json: JSON.stringify(this.checkboxGroup1),
              sku_json: JSON.stringify(this.tableData),
              description: this.basicForm.description,
            }).then(res => {
              if (!res.errno) {
                this.$refs["basicForm"].resetFields();
                this.tableData = [];
                this.checkboxGroup1 = [];
                this.$router.push("/app/supplier/produceManage");
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    handleClick() {
      this.isdisabled = this.tableData.length ? true : false;
    },
    // 产品规格
    productSpec(value) {
      console.log(value);
      let that = this;
      let data = [];
      value.forEach((v1, i1) => {
        this.dialogOptions.forEach((v, i) => {
          if (v.value_id == v1) {
            data[data.length] = this.dialogOptions[i].specs_value;
          }
        });
      });
      this.cities = data;
    },

    confirmDialog() {
      this.$http
          .propList({
            specs_json: JSON.stringify(this.checkboxGroup1)
          })
          .then(res => {
            this.tableData = res.data;
            this.specDialog = false;
          });
    },

    modify(id) {
      this.$http
          .queryProduct({
            gid: id
          })
          .then(res => {
            this.basicForm = res.data;
            this.basicForm.picture = JSON.parse(this.basicForm.picture)
            this.basicForm.status = res.data.status ? true : false;
            this.isdisabled = res.data.sku_json.length ? true : false;
            this.tableData = res.data.sku_json;
            this.checkboxGroup1 = res.data.specs_json; //返回成id
          });
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../../styles/app.scss";

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
  border: 1px solid #f5f5f5 !important;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.wrapper {
  display: flex;
  width: 100%;
}

.item {
  margin-top: 20px;
  width: 140px;
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
